exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-geography-index-js": () => import("./../../../src/pages/geography/index.js" /* webpackChunkName: "component---src-pages-geography-index-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-safety-index-js": () => import("./../../../src/pages/industrial-safety/index.js" /* webpackChunkName: "component---src-pages-industrial-safety-index-js" */),
  "component---src-pages-license-index-js": () => import("./../../../src/pages/license/index.js" /* webpackChunkName: "component---src-pages-license-index-js" */),
  "component---src-pages-project-index-js": () => import("./../../../src/pages/project/index.js" /* webpackChunkName: "component---src-pages-project-index-js" */),
  "component---src-pages-reference-index-js": () => import("./../../../src/pages/reference/index.js" /* webpackChunkName: "component---src-pages-reference-index-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

